nav {
    z-index: 9999;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    max-width: 100vw;
    background: white;
    height: 6vh;
}

.navList {
    margin: 0 2vw;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;
}

.navItem {
    height: 100%;
    display: block;
}

a {
    height: 100%;
    text-decoration: none;
    color: black;
    padding: 3vh .5vw;
}

    .navLink:hover{
        background-color: lightblue;
    }

.navIcon {
    font-size: 30px;
}