.projectContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3vh;
    width: 100%;
    margin: 0 auto;
}

.card{
    display: flex;
    flex-direction: column;
    gap: 10px;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.4);
    padding: 3vh 2vw;
    border-radius: 20px;
    border: 1px solid rgb(211, 211, 211);
    box-shadow: inset 0 0 10px 7px rgba(211, 211, 221, 0.7);
    max-width: 40%;
}

.cardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectLinks{
    display: flex;
    justify-content: space-evenly;
}

    .link:hover {
        cursor: pointer;
    }

.tagContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

    .tags{
        display: flex;
        gap: 2px;
    }

        .tag{
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 16px;
            text-align: center;
            margin: 0
        }

            .language{
                background-color: lightgreen;
            }

            .technology{
                background-color: lightblue;
            }